import React, { useState } from 'react'
import { Link } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux'
import {
    changeIsActive
} from '../../context/example/layoutSlice'
const Header = (props) => {
    const isActive = useSelector((state) => state.counter.value)
    const dispatch = useDispatch()
    console.log('isActive---',isActive);
    return (
        <header role="banner" class="position-absolute">

            <div class="top-bar full-width hide-s hide-m">
                <div class="right">
                    <a href="tel:080055544444444" class="text-white text-primary-hover">more info: 0800 555 444</a> <span class="sep text-white">|</span> <a href="mailto:info@companyname.com" class="text-white text-primary-hover">write us: info@companyname.com</a>
                </div>
            </div>
            <nav class="background-transparent background-transparent-hightlight full-width sticky">
                <div class="s-12 l-2">
                    <a href="/" class="logo">
                    <img class="logo-white" src="img/logo.svg" alt="" />
                    <img class="logo-dark" src="img/logo-dark.svg" alt="" />
                    </a>
                </div>
                <div class="top-nav s-12 l-10">

                    <ul class="right chevron">
                        
                        <li className={(isActive=='' || isActive=='/')?"active closemenu":'closemenu'} ><Link to='/' onClick={()=>dispatch(changeIsActive('/'))}>Home</Link></li>
                        <li className={isActive=='about'?"active closemenu":'closemenu'} ><Link to='/about' onClick={()=>dispatch(changeIsActive('about'))}>About</Link></li>
                        <li className={isActive=='register'?"active closemenu":'closemenu'} ><Link to='/register' onClick={()=>dispatch(changeIsActive('register'))}>Register</Link></li>

                    </ul>
                </div>
                    </nav>
                </header>
                )
}

                export default Header


