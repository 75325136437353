import React,{useEffect, useState, Suspense} from 'react'
import { httpRequest } from '../api';
import { APIBASE, GET, JSONCONTENTTYPE, ABOUTUS1} from '../api/constant';
import TOP from '../components/layout/Top'

const About = () => {

    const [aboutusPage1Data, setAboutUspage1Data] = useState({
        title: '',
        subTitle: '',
        description: '',
        address: '',
        mobile: '',
        email: '',
        file: '',
        submit: null
    });
    
    useEffect(() => {
        window.scrollTo(0, 0)        
        async function fetchAboutUsPage1Data() {
            const getIntialData = await httpRequest(ABOUTUS1 + '/get', GET, {}, JSONCONTENTTYPE, null);
            console.log('getIntialData', getIntialData);
            if (getIntialData.status == 'success') {
                let data = getIntialData.data.doc;
                data.submit = null;
                if (data.file) {
                    data.file = APIBASE + data.file.replace('public', '');
                }
                console.log('data', data);
                setAboutUspage1Data(data);
            }
        }
        fetchAboutUsPage1Data();
        console.log('aboutusPage1Data---->', aboutusPage1Data.file);
    }, []);
    
    return (
        <>
        <main role="main">    
            <article>
                <header className="section-head background-parallax-container">  
                <div className="line">
                    <div className="s-12 m-12 l-7 xl-6 text-right">
                    <h1 className="text-white text-s-size-40 text-m-size-60 text-size-90 text-line-height-1 text-extra-strong text-uppercase margin-bottom-30">
                        About Us
                    </h1>
                    </div>          
                </div>
                <TOP />
                </header>

                <Suspense fallback={<h1>Loading data...</h1>} >
                    <section className="section background-white">      
                        <div className="line">
                            <div className="margin2x">         
                            <div className="m-12 l-6 text-right">
                                <h2 className="text-s-size-40 text-m-size-60 text-size-80 text-line-height-1 text-extra-strong text-uppercase margin-bottom-30">
                                {aboutusPage1Data.title}
                                </h2>
                                
                                <p className="text-dark text-size-20 margin-bottom-30">
                                {aboutusPage1Data.subTitle} 
                                </p>
                                
                                <p> {aboutusPage1Data.description}</p>

                            </div>
                            
                            <div className="m-12 l-6 margin-m-top-30">
                                <img src= {aboutusPage1Data.file} alt="" />
                            </div> 
                            </div>    
                        </div>      
                    </section> 

                    <section className="full-width background-white">
                            <div className="s-12 m-12 l-4">
                                <div style={{"background-image":'url(img/img-09.jpg)'}} className="contact-image" ></div>
                            </div>
                            <div className="s-12 m-12 l-8 text-center">
                                <div className="padding-2x">
                                <i className="icon-sli-location-pin text-primary text-size-30 center"></i>
                                <h2 className="text-size-20 margin-bottom-0 text-strong">Company Address</h2>                
                                <p>
                                {aboutusPage1Data.address}
                                </p> 
                                
                                <i className="icon-sli-envelope text-primary text-size-30 center margin-top-20"></i>
                                <h2 className="text-size-20 margin-bottom-0 text-strong">E-mail</h2>                
                                <a className="text-primary-hover">{aboutusPage1Data.email}</a>
                                
                                <i className="icon-sli-earphones-alt text-primary text-size-30 center margin-top-20"></i>
                                <h2 className="text-size-20 margin-bottom-0 text-strong">Phone Numbers</h2>                
                                <p>
                                {aboutusPage1Data.mobile}<br />
                                </p> 
                                </div>
                            </div>
                    </section>
                </Suspense>
            </article>  
        </main>
        </>
    )
}

export default About


