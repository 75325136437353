import React, { useEffect, useState, Suspense } from 'react'
import { httpRequest } from '../api';
import { APIBASE, POST,  JSONCONTENTTYPE, SIGNUP } from '../api/constant';
import TOP from '../components/layout/Top'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Register = () => {
    const [showCalendar, setShowCalendar] = useState(false);
    const [showdate, setShowDate] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [contact, setContact] = useState('');
    const [district, setDistrict] = useState('');
    const [dob, setDob] = useState('');
    const [savedob, setSaveDob] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('123456');
    const [passwordConfirm, setPasswordConfirm] = useState('123456');
    const [role, setRole] = useState('student');

    const handleChange = value => {
      let dateformat = value.toLocaleDateString().split('/')
      let changedDateFormat = dateformat[1]+"/"+dateformat[0]+"/"+dateformat[2]
      console.log('changedDateFormat---',changedDateFormat);
      setSaveDob(changedDateFormat)
      setDob(new Date(changedDateFormat));
      setShowDate(value.toLocaleDateString());
      setShowCalendar(false);
    };
    // const [newsLetterPage1Data, setNewsLetterpage1Data] = useState({
    //     firstName: '',
    //     lastName: '',
    //     district: '',
    //     contact: '',
    //     dob: '',
    //     email: '',
    //     submit: null
    // });

    async function save() {
        const data = {
            firstName: firstName,
            lastName: lastName,
            district: district,
            contact: contact,
            dob: savedob,
            email: email,
            password:password,
            passwordConfirm:passwordConfirm,
            role:role,
        }
        console.log('data--->', data);
        const upload = await httpRequest(SIGNUP, POST, data, JSONCONTENTTYPE, null);
        console.log('uploaded doc--->', upload);
        
        if (upload.status == 'error'){
            toast.warn(upload.message, { theme: 'dark' })
        }else{
            toast.success('User register Successfully', { theme: 'dark' });
            setFirstName('')
            setLastName('')
            setDistrict('')
            setContact('')
            setDob('')
            setEmail('')
            setShowDate('')
        }
        return { status: upload.status, message: upload.message };
    }
    return (        
        <>
            <ToastContainer />
            <Suspense fallback={<h1>Loading data...</h1>}>
                <main role="main">
                    <article>
                        <header class="section-head background-parallax-container">
                            <div class="line">
                                <div class="s-12 m-12 l-7 xl-6 text-right">
                                    <h1 class="text-white text-s-size-40 text-m-size-60 text-size-90 text-line-height-1 text-extra-strong text-uppercase margin-bottom-30">
                                        Register
                                    </h1>
                                </div>
                            </div>
                            <TOP />
                        </header>
                        <section class="section background-image" style={{"background-image":'url(img/parallax-02.jpg)'}}>
                            <div class="s-12 m-12 l-4 center">
                            <h3 class="text-white text-size-30 margin-bottom-40 text-center">Registration Form (West Bengal only)</h3>

                                <form name="contactForm" class="customform text-white" method="post" enctype="multipart/form-data">
                                    <div class="line">
                                        <div class="margin">
                                            <div class="s-12 m-12 l-6">
                                                <input name="firstname" onChange={e=>setFirstName(e.target.value)} value={firstName} class="required firstname" placeholder="Your First Name" title="Your First Name" type="text" />
                                            </div>
                                            <div class="s-12 m-12 l-6">
                                                <input name="lastname"  onChange={e=>setLastName(e.target.value)} value={lastName} class="lastname" placeholder="Your Last Name" title="Your Last Name" type="text" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="line">
                                        <div class="s-12">
                                            <input name="dob" 
                                                value={showdate}
                                                onFocus={() => setShowCalendar(true)} 
                                                class="required dob" 
                                                placeholder="Date of Birth" 
                                                title="Date of Birth" 
                                                type="text" />
                                            <p class="dob-error form-error">Please enter your Date of Birth.</p>
                                            <Calendar
                                                className={showCalendar ? "" : "hide"}
                                                value={dob}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div class="s-12">
                                            <input name="district"  onChange={e=>setDistrict(e.target.value)} value={district}  class="required district" placeholder="District" title="District" type="text" />
                                            <p class="district-error form-error">Please enter your district.</p>
                                        </div>
                                        <div class="s-12">
                                            <input name="contact"  onChange={e=>setContact(e.target.value)} value={contact}  class="required contact" placeholder="Contact" title="Contact No." type="text" />
                                            <p class="contact-error form-error">Please enter your contact.</p>
                                        </div>
                                        <div class="s-12">
                                            <input name="email"  onChange={e=>setEmail(e.target.value)} value={email}  class="required email" placeholder="Email" title="Email" type="text" />
                                            <p class="email-error form-error">Please enter your email.</p>
                                        </div>
                                        <div class="s-12">
                                            <button 
                                                class="button border-radius text-white background-primary" 
                                                type="button" 
                                                onClick={()=>save()}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <img class="arrow-object" src="img/object-red.svg" alt="" />
                        </section>
                    </article>
                </main>
            </Suspense>
        </>
    )
}

export default Register


