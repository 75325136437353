import React,{useEffect, useState, Suspense} from 'react'
import { httpRequest } from '../api';
import { APIBASE, GET, JSONCONTENTTYPE, CONTACTUS1 } from '../api/constant';


const Contact = () => {
    const [contactUsPage1Data, setContactpage1Data] = useState({
        title: '',
        file: '',
        submit: null
    });
    useEffect(() => {
        window.scrollTo(0, 0)
        async function fetchData() {
            const getIntialData = await httpRequest(CONTACTUS1 + '/get', GET, {}, JSONCONTENTTYPE, null);
            console.log('getIntialData', getIntialData);
            if (getIntialData.status == 'success') {
                let data = getIntialData.data.doc;
                data.submit = null;
                if (data.file) {
                    data.file = APIBASE + data.file.replace('public', '');
                }
                console.log('data', data);
                setContactpage1Data(data);
            }
        }
        fetchData();
        console.log('contactUsPage1Data---->', contactUsPage1Data.file);
    }, []);
    return (
        <>
            <Suspense fallback={<h1>Loading data...</h1>} >
                <main role="main">    
                    <article>
                        <header className="section-head background-parallax-container">  
                        <div className="line">
                            <div className="s-12 m-12 l-7 xl-6 text-right">
                            <h1 className="text-white text-s-size-40 text-m-size-60 text-size-90 text-line-height-1 text-extra-strong text-uppercase margin-bottom-30">
                                Contact Us
                            </h1>
                            </div>          
                        </div>
                        <div className="background-parallax" style={{"background-image":'url(img/parallax-09.jpg)'}}></div> 
                        </header>
                        
                        <section className="full-width background-white">
                        <div className="s-12 m-12 l-4">
                            <div style={{"background-image":'url(img/img-09.jpg)'}} className="contact-image" ></div>
                        </div>
                        <div className="s-12 m-12 l-8 text-center">
                            <div className="padding-2x">
                            <i className="icon-sli-location-pin text-primary text-size-30 center"></i>
                            <h2 className="text-size-20 margin-bottom-0 text-strong">Company Address</h2>                
                            <p>
                            Kolkata
                            </p> 
                            
                            <i className="icon-sli-envelope text-primary text-size-30 center margin-top-20"></i>
                            <h2 className="text-size-20 margin-bottom-0 text-strong">E-mail</h2>                
                            <a className="text-primary-hover" href="mailto:rajdeepc67@gmail.com">rajdeepc67@gmail.com</a>
                            
                            <i className="icon-sli-earphones-alt text-primary text-size-30 center margin-top-20"></i>
                            <h2 className="text-size-20 margin-bottom-0 text-strong">Phone Numbers</h2>                
                            <p>
                                0800 4521 800 50<br />
                            </p> 
                            </div>
                        </div>
                        </section>
                        
                    </article>  
                </main>

            </Suspense>
                </>
           )
}

export default Contact


