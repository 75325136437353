import React,{useEffect, useState, Suspense} from 'react'
import { httpRequest } from '../api';
import { Link } from 'react-router-dom';
import TOP from '../components/layout/Top'
import { APIBASE, GET, JSONCONTENTTYPE, HOMEPAGE1 } from '../api/constant';

const Home = () => {
    const [homepage1Data, setHomepage1Data] = useState({
        title: '',
        subTitle: '',
        file: '',
        submit: null
    });
    useEffect(() => {
        window.scrollTo(0, 0)
        async function fetchData() {
            const getIntialData = await httpRequest(HOMEPAGE1 + '/get', GET, {}, JSONCONTENTTYPE, null);
            console.log('getIntialData', getIntialData);
            if (getIntialData.status == 'success') {
                let data = getIntialData.data.doc;
                data.submit = null;
                if (data.file) {
                    data.file = APIBASE + data.file.replace('public', '');
                }
                console.log('data', data);
                setHomepage1Data(data);
            }
        }
        fetchData();
        console.log('homepage1Data---->', homepage1Data);
    }, []);
  return (        
        <Suspense fallback={<h1>Loading data...</h1>} >
          <main role="main">    
              <header className="section-head background-parallax-container">  
              <div className="line">
                  <div className="s-12 m-12 l-7 xl-5 text-right">
                  <h1 className="text-white text-s-size-40 text-m-size-60 text-size-90 text-line-height-1 text-extra-strong text-uppercase margin-bottom-30 animation">
                      {homepage1Data.title}
                  </h1>
                  
                  <p className="text-white text-size-20 margin-bottom-40 animation">{homepage1Data.subTitle}</p>           
                  <Link to='/register' className="button background-white text-dark">Register</Link>
                  </div>          
              </div>
              <TOP />
              </header>
          </main>
        </Suspense>
    )
}

export default Home