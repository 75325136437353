import React,{useEffect, useState, Suspense} from 'react'
import { httpRequest } from '../../api';
import { APIBASE, GET, JSONCONTENTTYPE, HOMEPAGE1 } from '../../api/constant';

const Top = () => {
    const [homepage1Data, setHomepage1Data] = useState({
        title: '',
        subTitle: '',
        file: '',
        submit: null
    });
    useEffect(() => {
        window.scrollTo(0, 0)
        async function fetchData() {
            const getIntialData = await httpRequest(HOMEPAGE1 + '/get', GET, {}, JSONCONTENTTYPE, null);
            console.log('getIntialData', getIntialData);
            if (getIntialData.status == 'success') {
                let data = getIntialData.data.doc;
                data.submit = null;
                if (data.file) {
                    data.file = APIBASE + data.file.replace('public', '');
                }
                console.log('data', data);
                setHomepage1Data(data);
            }
        }
        fetchData();
        console.log('homepage1Data---->', homepage1Data);
    }, []);
  return (        
            <Suspense fallback={<h1>Loading data...</h1>}>
                <div className="background-parallax" style={{backgroundImage: 'url('+homepage1Data.file+')'}}></div>
            </Suspense>
        )
}

export default Top


